import Form from '@/components/form';
import FormSelect from '@/components/form/fields/select';
import FormTextField from '@/components/form/fields/textField';
import TextFieldInputLabel from '@/components/form/inputLabel';
import { ModalFormWrapper } from '@/components/form/modal';
import currencyFormat from '@/helpers/currencyFormat';
import useUserInfo from '@/providers/auth/useUserInfo';
import { Device, Order } from '@/types/schema';
import { Box, InputAdornment, ListItem, ListItemText, MenuItem, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { round } from 'lodash-es';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function TerminalDevicePaymentModal( { invoice, devices }: { invoice: Order, devices: Device[] } ) {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const { staff } = useUserInfo();
	
	const remainingAmount = invoice.grandTotal - invoice.paidTotal;
	const cardFee = invoice.company.metadata?.cardFee;
	
	return (
		<Form<{ amountType: string, cardType: string, amount: number, device: string }>
			initialValues={{
				amountType: 'fullAmount',
				cardType  : 'credit',
				amount    : remainingAmount,
				device    : devices[ 0 ].id,
			}}
			onSubmit={async ( values ) => {
				const selectedDevice = devices.find( ( d ) => d.id === values?.device );
				if ( !selectedDevice ) {
					enqueueSnackbar( 'Error selecting device!', { variant: 'error' } );
					return;
				}
				enqueueSnackbar( 'Sending payment request to device...', { variant: 'info' } );
				const { data } = await axios.post( '/api/processor/cardConnect/terminal/sendPayment', {
					merchantId: selectedDevice?.gateway?.externalKey,
					amount:
						values.amountType === 'percentAmount'
							? round( remainingAmount * values.amount / 100, 2 )
							: values.amountType === 'fullAmount'
								? remainingAmount
								: values.amount,
					hsn           : selectedDevice.serial,
					terminalApiKey: selectedDevice?.gateway?.terminalApiKey || selectedDevice?.apiKey,
					orderId       : invoice.id,
					cardType      : values.cardType,
					gatewayId     : selectedDevice?.gateway?.id,
					clientId      : invoice?.client?.id || null,
					paidTotal     : invoice.paidTotal,
					grandTotal    : invoice.grandTotal,
					staffId       : staff?.id,
					companyId     : invoice.company.id,
					metadata      : staff?.company?.metadata,
				} );
				
				if ( data?.result?.status === 'A' ) {
					enqueueSnackbar( 'Payment recorded successfully!', { variant: 'success' } );
				} else if ( data?.result?.status === 'C' ) {
					enqueueSnackbar( 'Payment declined!', { variant: 'error' } );
				} else if ( !data?.result ) {
					enqueueSnackbar( 'Error sending payment to device! Please make sure the device is properly set up and connected!', { variant: 'error' } );
				}
				if ( !data?.updated && invoice.company?.metadata?.stockMethod === 'manualPaymentStock' ) {
					enqueueSnackbar( 'Stock could not be updated!', { variant: 'error' } );
				}
			}}>
			{( formik ) => {
				const selectedDevice = devices.find( ( d ) => d.id === formik.values.device );
				return (
					<ModalFormWrapper
						title='Payment Amount'
						saveButtonProps={{ disabled: !remainingAmount }}
						saveButtonText='Pay'>
						<Stack spacing={1}>
							{devices.length > 1 ? (
								<FormSelect
									fullWidth
									name='device'
									label='Select Device'
									size='small'>
									{devices.map( ( device ) => (
										<MenuItem key={device.id} value={device.id}>
											{device.productName || device.label || device.serial}
										</MenuItem>
									) )}
								</FormSelect>
							) : (
								<Typography variant='h6'>Device: {selectedDevice.productName || selectedDevice.label || selectedDevice.serial}</Typography>
							)}
							{formik.values.device && (
								<Box>
									<Stack direction='row' alignItems='center' spacing={1}>
										<FormSelect
											name='amountType'
											sx={{ width: 100 }}
											size='small'
											onChange={( e ) => {
												formik.setValues( {
													...formik.values,
													amountType: e.target.value,
													amount:
														e.target.value === 'fullAmount'
															? remainingAmount
															: formik.values.amount,
												} );
											}}>
											<MenuItem value='fullAmount'>{t( 'commerce:full' )}</MenuItem>
											<MenuItem value='dollarAmount'>$</MenuItem>
											<MenuItem value='percentAmount'>%</MenuItem>
										</FormSelect>
										<FormTextField
											fullWidth
											disabled={formik.values.amountType === 'fullAmount'}
											type='number'
											name='amount'
											placeholder={t( 'common:amount' )}
											InputProps={{
												startAdornment: (
													<InputAdornment position='start'>
														{formik.values.amountType === 'percentAmount' ? '%' : '$'}
													</InputAdornment>
												),
											}}
											onFocus={( e ) => e.target.select()}
											onChange={( e ) => {
												const value = +e.target.value;
												let amount;
												switch ( formik.values.amountType ) {
													case 'dollarAmount':
														amount = value > remainingAmount ? remainingAmount : value;
														break;
													case 'percentAmount':
														amount = value > 100 ? 100 : value < 0 ? 0 : value;
														break;
													default:
														amount = remainingAmount;
												}
												formik.setFieldValue( 'amount', amount );
											}}
										/>
									</Stack>
									<Box my={1} sx={{ minWidth: 300 }}>
										<TextFieldInputLabel label='Card Type'/>
										<FormSelect fullWidth name='cardType'>
											<MenuItem value='credit'>
												{cardFee && cardFee !== 0 ? `Credit card (${round( cardFee, 2 )}% Charge)` : 'Card'}
											</MenuItem>
											{cardFee && (
												<MenuItem value='debit'>
													Debit Card
												</MenuItem>
											)}
										</FormSelect>
									</Box>
									<Box>
										<ListItem disableGutters>
											<ListItemText primary={t( 'common:total' )}/>
											<Typography variant='h3'>
												{currencyFormat( remainingAmount )}
											</Typography>
										</ListItem>
										<ListItem disableGutters>
											<ListItemText primary={t( 'common:paying' )}/>
											<Typography variant='h3'>
												{currencyFormat(
													formik.values.amountType === 'percentAmount'
														? round( remainingAmount * formik.values.amount / 100, 2 )
														: formik.values.amount,
												)}
											</Typography>
										</ListItem>
									</Box>
								</Box>
							)}
						</Stack>
					</ModalFormWrapper>
				);
			}}
		</Form>
	);
}
