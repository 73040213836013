import type { ActionProps } from '@/components/actions';
import PageLinkComponent from '@/components/page/linkComponent';
import { InvoiceOrderEstimateEmailModal } from '@/modals/email/invoiceOrderEstimateEmailModal';
import useWriteLog from '@/pages/dashboard/commerce/components/utils';
import usePermissions, { permissions } from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModal } from '@/providers/modal';
import { Order } from '@/types/schema';
import { getBrowserTimezone } from '@/utils/timezone';
import { urlSearchParams } from '@/utils/urlSearchParams';
import {
	Edit as EditIcon,
	Email as EmailIcon,
	PictureAsPdf as PictureAsPdfIcon,
	Print as PrintIcon,
} from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { isEmpty, isNil, omitBy, toLower, upperFirst } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useGetDeviceInfo from '../../../../../hooks/useGetDeviceInfo';
import { stayOpen } from '../../components/tableHelpers';

export function usePartialEstimateTableActions() {
	const { showModal } = useModal();
	const { isCloverDevice } = useGetDeviceInfo();
	const editable = usePermissions( permissions.estimates.write );
	const { t } = useTranslation();
	const { staff, user } = useUserInfo();
	const timezone = getBrowserTimezone();
	const theme = useTheme();
	const logWrite = useWriteLog();
	
	return ( estimate: Order, closeModal?: () => void ) => {
		const dataColor = estimate.metadata?.documentLayout?.color || staff?.company.metadata?.documentLayout?.color;
		const themeBasedLayoutColor = !isEmpty( dataColor?.light ) ? dataColor?.light?.slice( 1 ) : undefined;
		const type = upperFirst( toLower( estimate.type ) );
		const queryParams = urlSearchParams( {
			timezone,
			themeBasedLayoutColor,
			themeMode: theme.palette.mode,
		} );
		
		return [
			editable && {
				name   : t( 'common:edit' ),
				icon   : <EditIcon/>,
				onClick: () => closeModal?.(),
				props  : {
					component: PageLinkComponent,
					href     : `/dashboard/commerce/estimates/${estimate.id}/edit`,
				},
			}, {
				name   : isEmpty( estimate.metadata?.sentDates ) ? t( 'common:email' ) : t( 'common:remail' ),
				icon   : <EmailIcon/>,
				onClick: async () => showModal( InvoiceOrderEstimateEmailModal, {
					onClose: ( event, reason ) => stayOpen( event, reason ),
				}, { id: estimate.id } ),
			}, {
				name   : t( 'common:print' ),
				icon   : <PrintIcon/>,
				onClick: async () => {
					window.open( `/api/preview/print/${estimate.id}/invoice?${queryParams}`, '_blank' );
					
					await logWrite( {
						company   : estimate.company.id,
						name      : 'Print',
						method    : `Printed ${type}`,
						documentId: estimate.id,
						user      : user?.id,
						table     : 'ESTIMATE',
					} );
					
				},
			}, !isCloverDevice && {
				name   : t( 'common:pdf' ),
				icon   : <PictureAsPdfIcon/>,
				onClick: async () => {
					window.open( `/api/preview/pdf/${estimate.id}/estimate?${queryParams}`, '_blank' );
					
					await logWrite( {
						company   : estimate.company.id,
						name      : 'PDF',
						method    : `PDF ${type}`,
						documentId: estimate.id,
						user      : user?.id,
						table     : 'ESTIMATE',
					} );
					
				},
			}, !isCloverDevice && {
				name   : t( 'common:pdf-no-price' ),
				icon   : <PictureAsPdfIcon/>,
				props  : {
					component: PageLinkComponent,
					href     : `/api/preview/pdf/${estimate.id}/estimate?s=pickup&${queryParams}`,
					target   : '_blank',
				},
				onClick: async () => {
					window.open( `/api/preview/pdf/${estimate.id}/estimate?s=pickup&${queryParams}`, '_blank' );
					
					await logWrite( {
						company   : estimate.company.id,
						name      : 'PDF',
						method    : `PDF No Price ${type}`,
						documentId: estimate.id,
						user      : user?.id,
						table     : 'ESTIMATE',
					} );
					
				},
			},
		] as ActionProps[];
	};
}
